import React from 'react'
//import {useIntl} from "gatsby-plugin-intl"
import Layout from '../../components/layout'
import Header from '../../components/header'


const MesssystemFibreshape = () => (
  <Layout>
    
    <Header 
      title='Messsystem Fibreshape'
      text=''
    />
    
    <div className='row'
    style={{
      margin: '0 auto',
         
    }}>
        <div className='col-12'>

        <h3>FibreShape - measuring the length and fineness of natural or man-made fibers
        </h3>
        <h4> Interested in the length of carbon fibers or the fineness of cotton fibers? </h4>
        <p> 
        The powerful FibreShape systems allow you to correctly measure sizes and shapes of fibers both in dry and in 
        liquid suspended form. The FibreShape measurement system consists of  a computer, an appropriate high resolution scanner or 
        a microscope and the FibreShape software package.
        </p>
        <h4>Advantages you have using FibreShape are:</h4>
        <br/> &bull; the maximum size of a scanned image that can be analysed is 297 mm × 420 mm (A3)
        <br/> &bull; wide size range: fibre length of 3nm to 30cm and a fineness size range of 1nm to 3mm
        <br/> &bull; for fiber length > 15 µm the FibreShape software is combined with an appropriate scanner
        <br/> &bull; for fiber length > 3 µm the FibreShape software is combined with an appropriate light microscope
        <br/> &bull; for fiber length > 3 nm the FibreShape software is combined with an appropriate electron microscope

        <p>
        <br/> &bull; the FibreShape software is the only image analysis software that works with a fractal based algorithm 
        <br/> &bull; in focus image analysis (every particle in focus) 
        <br/> &bull; reports are according to ISO 9276 
        <br/> &bull; analysis can be done in dry and in liquid suspended form 
        <br/> &bull; the physical dynamic range of fibers in length and width can be analysed over a factor 1000. Using static image analysis the size range is much bigger than with dynamic image analysis 
        </p>
        <p>
        FibreShape is the ideal measurement system to make quality control of fiber morphology under constant measurement conditions. 
        It enables to select different measurement conditions for different fiber materials. Furthermore FibreShape is the right measurement 
        system for research and development as well as quality inspection of fibers. The user can develop himself different measurement 
        onditions in order to find out the optimum parameter sets for different fiber qualities.
        <br/>
        With FibreShape CROSS we have a powerful measurement system that can analyze the length of stiff crossing fibers 
        (e.g. carbon fibers, glass fibers). FibreShape CROSS detects intersecting fibers, splits it into its individual fibers and measures 
        the length of each individual fiber. 
        </p>
        More information LINK
        </div>

    </div>

    

    </Layout>
)


export default MesssystemFibreshape;